<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-width="190px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="mt-4 vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <nav-menu-section-title title="Admin Tools"></nav-menu-section-title>
      <nav-menu-link title="Shops" :to="{ name: 'shops' }" :icon="icons.mdiStore"></nav-menu-link>
       <nav-menu-link
          title="Sync Delayed"
          :icon="icons.mdiClockAlert"
          :to="{ name: 'sync-delayed-shops' }"
        ></nav-menu-link>

      <nav-menu-group title="Queues" :icon="icons.mdiTrayFull">
        <nav-menu-link title="Laravel Queues" :to="{ name: 'laravel-queues' }" :icon="icons.mdiLaravel"></nav-menu-link>
        <nav-menu-link title="Joomla Queues" :to="{ name: 'joomla-queues' }" :icon="icons.mdiAutorenew"></nav-menu-link>
        <nav-menu-link title="Failed Queues" :to="{ name: 'failed-queues' }" :icon="icons.mdiAlert"></nav-menu-link>
      </nav-menu-group>
      <nav-menu-group title="FAQ" :icon="icons.mdiTrayFull">
        <nav-menu-link
          title="Section"
          :icon="icons.mdiClockAlert"
          :to="{ name: 'faq-section' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Content"
          :icon="icons.mdiClockAlert"
          :to="{ name: 'faq-content' }"
        ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-section-title title="Reporting"></nav-menu-section-title>
      <nav-menu-link title="Needs Attention" :to="{ name: 'feeds' }" :icon="icons.mdiListStatus"></nav-menu-link>
      <nav-menu-link title="Failed Orders" :to="{ name: 'failed-orders' }" :icon="icons.mdiListStatus"></nav-menu-link>

      <section v-if="loggedinshop">
        <nav-menu-section-title title="Shop Tools"></nav-menu-section-title>
        <nav-menu-group title="Shopify" :icon="icons.mdiAlphaSCircle">
          <nav-menu-link title="Collections" :to="{ name: 'shopify-collections' }"></nav-menu-link>
          <nav-menu-link title="Products" :to="{ name: 'shopify-products' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Product Feeds" :to="{ name: 'feeds' }" :icon="icons.mdiListStatus"></nav-menu-link>
        <nav-menu-link title="Orders" :to="{ name: 'orders' }" :icon="icons.mdiTruck"></nav-menu-link>
        <nav-menu-link
          title="Etsy Products"
          :icon="icons.mdiAlphaECircle"
          :to="{ name: 'etsy-products' }"
        ></nav-menu-link>
        <nav-menu-link
          title="Inventory"
          :icon="icons.mdiAlphaECircle"
          :to="{ name: 'etsy-inventory' }"
        ></nav-menu-link>
        <nav-menu-link title="Configuration" :to="{ name: 'configuration' }" :icon="icons.mdiCog"></nav-menu-link>
        <nav-menu-link title="Order API Mock" :to="{ name: 'order-mock-form' }" :icon="icons.mdiCog"></nav-menu-link>
        <nav-menu-link title="App Updates" :to="{ name: 'app-updates' }" :icon="icons.mdiListStatus"></nav-menu-link>
        <nav-menu-link title="Announcement" :to="{ name: 'announcement' }" :icon="icons.mdiBullhornOutline"></nav-menu-link>
        <nav-menu-link title="Log Out Shop" :to="{ name: 'logout-shop' }" :icon="icons.mdiLogout"></nav-menu-link>
      </section>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiStore,
  mdiLaravel,
  mdiAutorenew,
  mdiAlert,
  mdiCog,
  mdiListStatus,
  mdiCart,
  mdiLogout,
  mdiTruck,
  mdiAlphaECircle,
  mdiAlphaSCircle,
  mdiTrayFull,
  mdiClockAlert,
  mdiBullhornOutline
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import store from '@/store'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        mdiHomeOutline,
        mdiStore,
        mdiLaravel,
        mdiAutorenew,
        mdiAlert,
        mdiCog,
        mdiCart,
        mdiListStatus,
        mdiLogout,
        mdiTruck,
        mdiAlphaECircle,
        mdiAlphaSCircle,
        mdiTrayFull,
        mdiClockAlert,
        mdiBullhornOutline
      },
      loggedinshop: null,
    }
  },
  mounted() {
    this.loggedinshop = store.getters.loggedInShop
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
