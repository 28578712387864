import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "./../store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      authRequired: true,
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/shops',
    name: 'shops',
    component: () => import('@/views/admin-dashboard/Shops/ShopsList.vue'),
  },
  {
    path: '/sync-delayed-shops',
    name: 'sync-delayed-shops',
    component: () => import('@/views/admin-dashboard/Shops/ShopsList.vue'),
  },
  {
    path: '/shops/:shop_id',
    name: 'shops-detail',
    component: () => import('@/views/admin-dashboard/Shops/ShopDetail.vue'),
  },
  {
    path: '/laravel-queues',
    name: 'laravel-queues',
    component: () => import('@/views/admin-dashboard/LaravelQueue/LaravelQueue.vue'),
  },
  {
    path: '/joomla-queues',
    name: 'joomla-queues',
    component: () => import('@/views/admin-dashboard/JoomlaQueues/JoomlaQueues.vue'),
  },
  {
    path: '/failed-queues',
    name: 'failed-queues',
    component: () => import('@/views/admin-dashboard/FailedQueues/FailedQueues.vue'),
  },

  {
    path: '/failed-queues',
    name: 'failed-queues',
    component: () => import('@/views/admin-dashboard/Shops/ShopsList.vue'),
  },

  {
    path: '/faq-section',
    name: 'faq-section',
    component: () => import('@/views/admin-dashboard/FAQ/SectionList.vue')
  },
  {
    path: '/faq-content',
    name: 'faq-content',
    component: () => import('@/views/admin-dashboard/FAQ/ContentList.vue')
  },
  //REPORTING
  {
    path: '/failed-orders',
    name: 'failed-orders',
    component: () => import('@/views/admin-dashboard/Reporting/FailedOrders.vue'),
  },


  //SHOP TOOLS
  {
    path: '/feeds',
    name: 'feeds',
    component: () => import('@/views/admin-dashboard/Feeds/Feeds.vue'),
  },

  {
    path: '/shopify-collections',
    name: 'shopify-collections',
    component: () => import('@/views/admin-dashboard/Shopify/Collections/AllCollections.vue'),
  },

  {
    path: '/shopify-collections/:collection_id/products',
    name: 'shopify-collections-products',
    component: () => import('@/views/admin-dashboard/Shopify/Collections/CollectionProducts.vue'),
  },


  {
    path: '/shopify-products',
    name: 'shopify-products',
    component: () => import('@/views/admin-dashboard/Shopify/Products/Products.vue'),
  },


  {
    path: '/shopify-products/:product_id',
    name: 'shopify-product',
    component: () => import('@/views/admin-dashboard/Shopify/Products/Product.vue'),
  },

  {
    path: '/feeds/:feed_id',
    name: 'feed-details',
    component: () => import('@/views/admin-dashboard/Feeds/FeedDetails.vue'),
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('@/views/admin-dashboard/Configuration/Configuration.vue'),
  },
  {
    path: '/etsy-products',
    name: 'etsy-products',
    component: () => import('@/views/admin-dashboard/EtsyProducts/EtsyProducts.vue'),
  },
  {
    path: '/etsy-products/:etsy_product_id',
    name: 'etsy-product',
    component: () => import('@/views/admin-dashboard/EtsyProducts/ProductDetail.vue'),
  },
  {
    path: '/inventory',
    name: 'etsy-inventory',
    component: () => import('@/views/admin-dashboard/EtsyInventories/EtsyInventories.vue'),
  },
  {
    path: '/order-mock-form',
    name: 'order-mock-form',
    component: () => import('@/views/admin-dashboard/Orders/Mock/OrderMockForm.vue'),
  },
   {
    path: '/order-mock-list',
    name: 'order-mock-list',
    component: () => import('@/views/admin-dashboard/Orders/Mock/OrderMockList.vue'),
  },
   {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/admin-dashboard/Orders/Orders.vue'),
  },
  {
    path: '/app-updates',
    name: 'app-updates',
    component: () => import('@/views/admin-dashboard/EtsyAppUpdate/AppUpdate.vue'),
  },
  {
    path: '/announcement',
    name: 'announcement',
    component: () => import('@/views/admin-dashboard/Announcement/Announcement.vue'),
  },
  {
    path: '/logout-shop',
    name: 'logout-shop',
    component: () => import('@/views/admin-dashboard/LogOut/LogOut.vue'),
  },

  // {
  //   path: '/icons',
  //   name: 'icons',
  //   component: () => import('@/views/icons/Icons.vue'),
  // },
  // {
  //   path: '/cards',
  //   name: 'cards',
  //   component: () => import('@/views/cards/Card.vue'),
  // },
  // {
  //   path: '/simple-table',
  //   name: 'simple-table',
  //   component: () => import('@/views/simple-table/SimpleTable.vue'),
  // },
  // {
  //   path: '/form-layouts',
  //   name: 'form-layouts',
  //   component: () => import('@/views/form-layouts/FormLayouts.vue'),
  // },
  // {
  //   path: '/pages/account-settings',
  //   name: 'pages-account-settings',
  //   component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  // },
  {
    path: '/admin-login',
    name: 'admin-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})


router.beforeEach((to, from, next) => {
  if (to.name == 'admin-login') {
    if (store.getters.isAuthenticated) {
      router.push({
        path: '/dashboard'
      })
    }
  }
  if (to.matched.some((m) => m.meta.authRequired)) {
    if (!store.getters.isAuthenticated) {
      router.push({
        path: '/admin-login'
      })
    }
  }

  return next();
});

export default router
